<template>
  <div class="inline ymms">
    <span class="year">{{ vehicle.Year }} </span>
    <span class="make">{{ vehicle.Make }} </span>
    <span class="model">{{ vehicle.Model }} </span>
    <span class="series">{{ vehicle.Trim }}</span>
  </div>
  
</template>
<script>
export default {
  props: {
    vehicle: Object,
    learnMore: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.ymms {
  font-family: $primary-header-font-family;
  font-size: 1.5rem;

  h5 {
    color: $accent-font-color;
  }

  .year {
    color: $primary-font-color-dark;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    font-weight: 800;
  }

  .make {
    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }

    font-weight: 800;
    color: $tertiary-font-color-dark;
  }

  .model,
  .series {
    font-weight: normal;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
    }
  }

  .series {
    font-size: 0.875rem;
    white-space: nowrap;
  }
}
</style>
